import { easeQuad } from 'd3-ease'

export const spring1 = (bool, delay = 1) => {
  return {
    to: { opacity: bool ? 1 : 0 },
    from: { opacity: 0 },
    delay: 200 * delay,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      clamp: false,
      precision: 0.01,
      velocity: 0,
      easing: easeQuad,
      damping: 1,
      progress: 0,
      duration: undefined,
      decay: undefined,
      frequency: undefined,
      round: undefined,
      bounce: undefined,
      restVelocity: undefined
    }
  }
}
