import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import CTA from '../lib/cta'
import BlockText from './block-text'
import { MaxWidthContainer, fluid, pStyle, cta } from '../styles/mixins'
import theme from '../styles/theme'
import styled from '@emotion/styled'
import _ from 'lodash'

function ProjectPreview (props) {

	const slug = _.get(props, 'slug.current')
	const internalLink = {
		title: 'Learn More',
		href: `/books/${slug}`,
		target: false
	}

  return (
    <ProjectContainer maxW="800px">
			<div className="holder">
				<div className="left">
					{props.mainImage && props.mainImage.asset && (
						<img
							src={imageUrlFor(buildImageObj(props.mainImage))
								.width(300)
              .height(Math.floor((16 / 11.5) * 300))
              .fit('crop')
								.url()}
							alt={props.mainImage.alt}
						/>
					)}
				</div>
				<div className="right">
					<h2 className="book-title">{props.title}</h2>
					{props._rawExcerpt && (
						<div>
							<BlockText blocks={props._rawExcerpt} />
						</div>
					)}
					{slug && 
						<CTA data={internalLink} />
					}
					{props.cta &&
						<CTA data={props.cta} />
					}
					
				</div>
			</div>
    </ProjectContainer>
  )
}

export default ProjectPreview

const ProjectContainer = styled(MaxWidthContainer)`
	.book-title {
			${fluid('font-size', 32, 40, 600, 1200)}
			color:${theme.colors.white};
			font-family:${theme.fonts.bold};
			margin-bottom:20px;
		}
	.holder {
		align-items: center;  
		display:flex;
		.left {
			img {
				${fluid('width', 160, 180, 540, 800)}
			}
			margin-right:50px;
		}
		.right {
			color:${theme.colors.white};
			h3 {
				font-family:${theme.fonts.bold};
				text-transform:uppercase;
				${fluid('font-size', 20, 24, 540, 800)}
			}
			p {
				${pStyle}
				margin-top:8px !important;
			}
			.links-container {
				display:flex;
				margin-top: 10px;
				a {
					img {
						width:40px;
					}
				}
			}
			.cta {
				${cta}
				margin-top:14px;
				display:inline-block;
				margin-right:20px;
				a {
					background-color:${theme.colors.green};
					&:hover {
						background-color:${theme.colors.brown};
					}
				}
			}
		}
	}

  @media(${theme.breakpoints.mobile}) {
		.holder {
			flex-direction:column;
			.left {
				margin-bottom: 20px;
				margin-right:0;
				width:100%;
				img {
					width:100% !important;
				}
			}
			.right {
				
			}
		}
  }
`
