import React from 'react'
import ProjectPreview from './project-preview'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import VisibilitySensor from 'react-visibility-sensor'
import { useSpring, animated } from 'react-spring'
import { spring1 } from '../utils/springs'

function ProjectPreviewGrid(props) {
  const projectNodes =
    props.nodes &&
    props.nodes.map(node => {
      const [sensorActive, setSensorActive] = React.useState(true)
      const onChange = isVisible => {
        if (isVisible) {
          set(true)
          setSensorActive(false)
        }
      }

      const [flip, set] = React.useState(false)
      const effect = useSpring(spring1(flip))

      return (
        <VisibilitySensor onChange={onChange} partialVisibility active={sensorActive}>
          <animated.li key={node.id} style={effect}>
            <ProjectPreview {...node} />
          </animated.li>
        </VisibilitySensor>
      )
    })

  return (
    <ProjectsContainer>
      <ul className="projects-list">{projectNodes}</ul>
    </ProjectsContainer>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid

const ProjectsContainer = styled.section`
  .projects-list {
    li {
      padding: 100px 0;
      &:nth-of-type(odd) {
        background-color: ${theme.colors.mud};
      }
      &:nth-of-type(even) {
        background-color: ${theme.colors.gold};
      }
    }
  }
`
