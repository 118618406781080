import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/modules/Hero'

import { mapEdgesToNodes } from '../lib/helpers'

export const query = graphql`
  query ProjectsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)books/" }) {
      _id
      header
      _rawBody
			mainImage {
				crop {
					_key
					_type
					top
					bottom
					left
					right
				}
				hotspot {
					_key
					_type
					x
					y
					height
					width
				}
				asset {
					_id
				}
				alt
			}
			cta {
				title
				href
				target
			}
    }
    projects: allSanityProject(limit: 20, sort: { fields: [sortOrder], order: ASC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
					cta {
						title
						href
						target
					}
          socialLinks {
            url
            title
            icon {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`

const ProjectsPage = props => {
  const { data, errors } = props
  const page = (data || {}).page
  
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects)


  return (
    <Layout>
      <SEO title='Projects' />
      <Container>
        <Hero
          page={page}
        />
        {projectNodes && projectNodes.length > 0 && 
          <ProjectPreviewGrid
            nodes={projectNodes}
            title="Our Investments"
          />
        }
      </Container>
    </Layout>
  )
}

export default ProjectsPage